module services {
    export module applicationcore {
        export class currencyService implements interfaces.applicationcore.ICurrencyService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            getCurrencyList(): ng.resource.IResourceClass<interfaces.applicationcore.ICurrencyResource> {
                var getList: ng.resource.IActionDescriptor = {
                    method: 'GET',
                    isArray: true
                }

                return <ng.resource.IResourceClass<interfaces.applicationcore.ICurrencyResource>>
                    this.$resource(this.ENV.DSP_URL + "Currency/GetList", {
                    }, {
                            query: getList
                        });
            }

            getCurrencyDropdownList(): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
                var getList: ng.resource.IActionDescriptor = {
                    method: 'GET',
                    isArray: true
                }

                return <ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel>>
                    this.$resource(this.ENV.DSP_URL + "Currency/GetDropdownList", {
                    }, {
                            query: getList
                        });
            }
            
            getCurrencyDetail(): ng.resource.IResourceClass<interfaces.applicationcore.ICurrencyResource> {
                var getDetail: ng.resource.IActionDescriptor = {
                    method: 'GET',
                    isArray: true
                }

                return <ng.resource.IResourceClass<interfaces.applicationcore.ICurrencyResource>>
                    this.$resource(this.ENV.DSP_URL + "Currency/GetDetail", {
                        id: '@id'
                    }, {
                            query: getDetail
                        });
            }
        }
    }
    angular.module("app").service("currencyService", services.applicationcore.currencyService);
}